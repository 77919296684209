import logo from './logo.svg';
import './App.css';
import Header from './components/Header';

function App() {
  return (
    <main>
      <Header />

    </main>
  );
}

export default App;
