import React from "react";
import {Navbar, Container, Nav, Button} from 'react-bootstrap';
import logoUltraFishPlus from '../img/ultrafishplus_hor.png';
import logoUltraFish from '../img/logoUltraFish_re_nbg.png';
import "./style.css"

function Header() {
    return (
        <Navbar className="color-nav" collapseOnSelect expand="lg" variant="light" sticky="top">
            <Container >
                <Nav.Item>
                <Navbar.Brand href="../" > 
                    <img
                    src={logoUltraFishPlus}
                    height="60"
                    className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                

                </Nav.Item>
                
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Navbar.Brand href="https://ultrafish.io" >
                    <img
                    src={logoUltraFish}
                    height="35"
                    className="d-inline-block align-right"
                    />
                </Navbar.Brand>
                </Navbar.Collapse>
            </Container>

        </Navbar>
    )
}

export default Header;