import React from 'react';
import { Col, Container, Row, Form, Button,InputGroup, FormControl,Alert,Nav} from 'react-bootstrap';
import {Link} from "react-router-dom";
import "./style.css";



function Josephus_Problem(){
	
    
    function josephusProblem(){
		var n = document.getElementById("n").value;
		var m = document.getElementById("m").value;

        if (n=="" && m=="") {
            clearAll();
            return;
        }
        else if (n=="" || m=="") {
            document.getElementById("goHeader").innerHTML = "";
            document.getElementById("resultHeader").innerHTML = "";
            document.getElementById("safeHeader").innerHTML = "";
            document.getElementById("originHeader").innerHTML = "";
        }

        else if (n!="" && m!="") {

            var str1 = n+'';
            var str2 = m+'';
            var flag1 = true;
            var flag2 = true;

            for (var k=0; k<str1.length;k++) {
                if ((str1.charAt(k)).charCodeAt()<48||(str1.charAt(k)).charCodeAt()>57) {flag1 = false; break;}
            }
            for (var j=0; j<str2.length;j++) {
                if ((str2.charAt(j)).charCodeAt()<48||(str2.charAt(j)).charCodeAt()>57) {flag2 = false; break;}
            }

            if (flag1 == false || flag2 == false){
                document.getElementById("goHeader").innerHTML = "WARNING! Both n and m should be integer, please try again!";
                document.getElementById("resultHeader").innerHTML = "";
                document.getElementById("safeHeader").innerHTML = "";
                document.getElementById("originHeader").innerHTML = "";
            }
            else if (m <= 0||n <= 0 ) {
                document.getElementById("goHeader").innerHTML = "WARNING! Both n and m should be grater than 0, please try again!";
                document.getElementById("resultHeader").innerHTML = "";
                document.getElementById("safeHeader").innerHTML = "";
                document.getElementById("originHeader").innerHTML = "";
            }
            else {
                var position = 1;
                var nowPosition = 1;
                var outQueue = new Array();
                var nowQueue = new Array();
                for (var i=1;i<=n;i++){
                    nowQueue.push(i);
                }
                if (m > 0 && n > 0){
                document.getElementById("originHeader").innerHTML = "Original Sequence: " + nowQueue;
                }
                while(nowQueue.length>0 && n!=0 && m!= 0){
                    position = (m-1) % nowQueue.length + 1;
                    if (nowPosition != position){
                        nowQueue.push(nowQueue[0]);
                        nowQueue.shift();
                        nowPosition += 1;
                        }
                    else{
                        outQueue.push(nowQueue[0]);
                        nowQueue.shift();
                        nowPosition = 1;
                    }
                }
                
                document.getElementById("goHeader").innerHTML = "";
                document.getElementById("resultHeader").innerHTML = "Out Sequence: " + outQueue;
                document.getElementById("safeHeader").innerHTML = "SAFE Number: " + outQueue.pop();
            }
        
        }
	}
	function clearAll(){
		document.getElementById("goHeader").innerHTML = "";
		document.getElementById("n").value = "";
		document.getElementById("m").value = "";
		document.getElementById("originHeader").innerHTML = "";
		document.getElementById("resultHeader").innerHTML = "";
		document.getElementById("safeHeader").innerHTML = "";
	}

    return(
        document.title = "Josephus Problem with Python - UltraFish Plus",
        window.scrollTo(0, 0),
        <Container fluid id="educations" style={{backgroundColor: "#ffffff", padding: "20px"}}>
            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
			<Col xs={12} md={6} >
        		<a style={{ color:'#777', textDecoration: 'none' }} href="/" > Home </a> <font style={{color:"#777"}}>{'>'} Josephus Problem with Python</font>
			</Col>
			</Row>
            <h3 style={{width: "100%", textAlign: "center", marginBottom: "50px", marginTop: "20px", color: "#000000"}}><b>Python对约瑟夫问题的高效解决方法</b>
                <br></br><b>Josephus Problem with Python</b></h3>
            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={10} sm={6} md={4} >

                <Form>
                    <Form.Group className="test" controlId='n'>
                        <Form.Label>The Number of People (N):</Form.Label>
                        <Form.Control  placeholder="N" onInput={josephusProblem}/>
                    </Form.Group>
                </Form>

                </Col>
                

                

            </Row>
            <Row style={{height: "4"}}>
                <br></br>
            </Row>
            <Row style={{justifyContent: "center", alignContent: "center", height: "80%"}}>
                <Col xs={10} sm={6} md={4}>
                <Form>
                    <Form.Group className="test" controlId='m'>
                        <Form.Label>The Count Number (M):</Form.Label>
                        <Form.Control  placeholder="M" onInput={josephusProblem}/>
                    </Form.Group>
                </Form>
                </Col>

                
            </Row>
            <Row className="justify-content-center">
            <Col xs={12} sm={8} md={4}>
            <Alert variant="light" style={{width: "100%",textAlign: "center",}}>
                Please enter <b>the Number of People (N)</b> and <b>the Count Number (M)</b> to go, result below.
                <p id="goHeader"></p>
                <p id="originHeader"></p>
				<p id="resultHeader"></p>
				<p id="safeHeader"></p>
                </Alert>
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
            <Button variant='outline-secondary' href='https://ultrafish.cn/2020/08/05/josephus-problem/' style={{width:"250px",textAlign: "center",}}><b>查看原文 Back to the Post</b></Button>{""}
            </Row>
			<br></br>
            <Row className="justify-content-center">
            <Link to="/" style={{width:"275px",textAlign: "center",}} >
            <Button style={{width:"100%",textAlign: "center",}} variant='outline-secondary' ><b>回到主页 Back to the Main Page</b></Button>{""}
            </Link>
            </Row>
			<br></br>
			 <Row className="justify-content-center">
            <Button variant='outline-secondary' href='https://ultrafish.io/about' style={{width:"250px",textAlign: "center",}}><b>报告问题 Report Issues</b></Button>{""}
			</Row>
            <Row style={{height: "4"}}>
                
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                
            </Row>
        </Container>
    );
}

export default Josephus_Problem;