import React from 'react';
import { Col, Container, Row, Form, Button,InputGroup, FormControl,Alert,Nav} from 'react-bootstrap';
import {Link} from "react-router-dom";
import "./style.css";



function IEEE_754_Single_Representation(){
    function ieee754(){

        if (document.getElementById("in1").value==""){
            clearAll();
        }
        else {

        
		
		var out = "";
		var sBit = '';
		var eBit = '';
		var fBit = new Array();
		var num = Number(document.getElementById("in1").value);
		var numAbs = Math.abs(num);
		if (numAbs == 1024){
			document.getElementById("out").innerHTML = "<strong>HAPPY 1024 Programmer's Day<br>1024程序员节快乐</strong><br><br>";
			window.location.href='https://baike.baidu.com/vbaike/%E7%A8%8B%E5%BA%8F%E5%91%98%E8%8A%82%E9%82%A3%E4%BA%9B%E5%B9%B4/48599';
		}
		if (numAbs==0){
			sBit = '0';
			eBit = '00000000';
			fBit = '00000000000000000000000';
		}
		
		else if (numAbs > (2-Math.pow(2,-23))*Math.pow(2,127)){
			//eBit = "Out of Range!";
			document.getElementById("in1").value = "Out of Range!";
		}
		else{
			if (num<0){
				sBit = "1";
			}
			else if(num>0){
				sBit = "0";
			}
		
			eBit = binary((((Math.floor(numAbs)).toString(2)).length+126),8)
		
			var i = 1;
			while(fBit.length<23){
				if (i >= (numAbs.toString(2)).length){
					fBit.push('0');
				}
				else if (numAbs.toString(2)[i] != '.'){
					fBit.push(numAbs.toString(2)[i]);
				}
				i = i + 1
			}
		}
		document.getElementById("in2s").value = sBit;
		document.getElementById("in2e").value = eBit;
		document.getElementById("in2f").value = fBit.join("");
        //var outVal = sBit.toString()+eBit.toString()+(fBit.join("")).toString();
        document.getElementById("out").innerHTML = "Actual Fraction:"+ "1." +(fBit.join("")).toString();
        }
	}

    function ieee754_re(){
		var out;
		var f = new Array();
		var sBit = document.getElementById("in2s").value;
		var eBit = document.getElementById("in2e").value;
		var fBit = document.getElementById("in2f").value;
        var lead = "";
		
		//document.getElementById("in2f").value = parseInt(parseInt(fBit) * Math.pow(10,23-(fBit.length)));
		
		if (eBit == "11111111"){
			//document.getElementById("leading").value = "";
            lead = "";
			if (parseInt(fBit,2) != 0){
				document.getElementById("in1").value = "NaN";
			}
			else if (parseInt(fBit,2) == 0){
				if (sBit == '1'){
					document.getElementById("in1").value = "-Inf";
				}
				else if (sBit == '0'){
					document.getElementById("in1").value = "Inf";
				}
				else{
					document.getElementById("in1").value = "Invalid Sign bit(0/1)";
				}
			}
		}
		else if (parseInt(eBit,2) == 0){
			//document.getElementById("leading").value = "0.";
            lead = "0."
			if (sBit == '1' && parseInt(fBit,2) == 0){
					document.getElementById("in1").value = "-0";
				}
			else if (sBit == '0' && parseInt(fBit,2) == 0){
					document.getElementById("in1").value = "0";
				}
			else{
					var i = 0;
					while(f.length<23){
							if (i >= fBit.length){
								f.push('0');
							}
							else {
								f.push(fBit[i]);
							}
							i = i + 1
						}
					document.getElementById("in2f").value = f.join("");

					var eVal = 1 - 127;

					var fVal = parseInt(fBit,2)/Math.pow(2,fBit.length);

					out = fVal*Math.pow(2,eVal);

					if (sBit == '1'){
						out = 0-out;
					}
					else if (sBit == "0"){
						out = out;
					}
					else{
						out = "Invalid Sign bit(0/1)";
					}
					document.getElementById("in1").value = out;
				}
		}
		else if(eBit.length>8){
			document.getElementById("in1").value = "Invalid Exponent (8-bit)"
		}
		else if (fBit.length>23){
			document.getElementById("in1").value = "Invalid Fraction (23-bit)"
		}
		else{
			//document.getElementById("leading").value = "1.";
            lead = "1."
			i = 0;
			while(f.length<23){
					if (i >= fBit.length){
						f.push('0');
					}
					else {
						f.push(fBit[i]);
					}
					i = i + 1
				}
			document.getElementById("in2f").value = f.join("");

			var eVal = parseInt(eBit,2) - 127;

			var fVal = parseInt(fBit,2)/Math.pow(2,fBit.length)+1;

			out = fVal*Math.pow(2,eVal);

			if (sBit == '1'){
				out = 0-out;
			}
			else if (sBit == "0"){
				out = out;
			}
			else{
				out = "Invalid Sign bit(0/1)";
			}
			document.getElementById("in1").value = out;
			}
            document.getElementById("out").innerHTML = "Actual Fraction: "+ lead +document.getElementById("in2f").value;
	}



    function binary(inNum,inLen){
		var outList = new Array();
		var bNum;
		bNum = inNum.toString(2);
		if (inLen < bNum.length){return null;}
		if (inLen == bNum.length){return bNum;}
		var i = 0;
		while(i<inLen){
			if (i < (inLen-bNum.length)){
				outList.push(0);
			}
			else{
				outList.push(bNum[i-(inLen-bNum.length)])
			}
			i=i+1;
		}
		return outList.join("");
	}

    function clearAll(){
		document.getElementById("in1").value = "";
		document.getElementById("in2s").value = "";
		document.getElementById("in2e").value = "";
		document.getElementById("in2f").value = "";
		document.getElementById("out").innerHTML = "";
	}

    return(
		document.title = "Floating Point Numbers Representation Convertor - UltraFish Plus",
		window.scrollTo(0, 0),
        <Container fluid id="educations" style={{backgroundColor: "#ffffff", padding: "20px"}}>
			<Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
			<Col xs={12} md={6} >
        		<a style={{ color:'#777', textDecoration: 'none' }} href="/" > Home </a> <font style={{color:"#777"}}>{'>'} Floating Point Numbers Convertor</font>
			</Col>
			</Row>
            <h3 style={{width: "100%", textAlign: "center", marginBottom: "50px", marginTop: "20px", color: "#000000"}}><b>浮点数表示方法转换器</b>
                <br></br><b>Floating Point Numbers Convertor</b><br></br><b>(IEEE 754 single precision)</b></h3>
            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={12} sm={12} md={4} >

                <Form>
                    <Form.Group className="test" controlId='in1'>
                        <Form.Label>Dec:</Form.Label>
                        <Form.Control  placeholder="Enter" onInput={ieee754}/>
                    </Form.Group>
                </Form>

                </Col>
            </Row>
            <Row style={{height: "4"}}>
                <br></br>
            </Row>
            <Row style={{justifyContent: "center", alignContent: "center", height: "80%"}}>
                <Col xs={3} sm={3} md={1}>
                <Form>
                    <Form.Group className="test" controlId='in2s'>
                        <Form.Label>Bin:</Form.Label>
                        <Form.Control  placeholder="Sign" />
                    </Form.Group>
                </Form>
                </Col>

                <Col xs={4} sm={4} md={1}>
                <Form>
                    <Form.Group className="test" controlId='in2e'>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Control  placeholder="Exponent" />
                    </Form.Group>
                </Form>
                </Col>
                <Col xs={5} md={2}>
                <Form>
                    <Form.Group className="test" controlId='in2f'>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Control  placeholder="Fraction" />
                    </Form.Group>
                </Form>
                </Col>
            </Row>
            <br></br>
            

            <Row className="justify-content-center">
            <Button variant='outline-secondary' onClick={ieee754_re} style={{width:"95px",textAlign: "center",}}><b>to Dec</b></Button>{""}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button variant='outline-secondary' onClick={clearAll} style={{width:"95px",textAlign: "center",}}><b>Clear</b></Button>{""}
            </Row>

            <br></br>

            <Row className="justify-content-center">
            <p id="out" style={{width:"100%",textAlign: "center",}}></p>
            </Row>

			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
            <Row className="justify-content-center">
            <Button variant='outline-secondary' href='https://ultrafish.cn/2020/12/20/floating%20point%20numbers%20representation/' style={{width:"250px",textAlign: "center",}}><b>查看原文 Back to the Post</b></Button>{""}
            </Row>
            
			<br></br>
            <Row className="justify-content-center">
            <Link to="/" style={{width:"275px",textAlign: "center",}} >
            <Button style={{width:"100%",textAlign: "center",}} variant='outline-secondary' ><b>回到主页 Back to the Main Page</b></Button>{""}
            </Link>
            </Row>
			<br></br>
			 <Row className="justify-content-center">
            <Button variant='outline-secondary' href='https://ultrafish.io/about' style={{width:"250px",textAlign: "center",}}><b>报告问题 Report Issues</b></Button>{""}
			</Row>
            <Row style={{height: "4"}}>
                
                <br></br>
                <br></br>
                
                
                
            </Row>
        </Container>
    );
}

export default IEEE_754_Single_Representation;