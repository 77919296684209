import React from 'react';
import { Col, Container, Row, Form, Button,InputGroup, FormControl,Alert,Nav} from 'react-bootstrap';
import {Link} from "react-router-dom";
import "./style.css";



function Signed_Binary_Number_Convertor(){

    function dtob() {
        if (document.getElementById("in1").value == "") {
            clearAll();
            return;
        } 
        var choice = document.getElementById("typeSelector").value;
        if (choice == 0) {
            signedMagnitude();
        }
        else if (choice == 1) {
            onesComplement();
        }
        else if (choice == 2) {
            twosComplement();
        }
        else if (choice == 3) {
            excessEightbits();
        }
        else {
            twosComplement();
        }
    }

    function btod() {
        if (document.getElementById("in2").value == "") {
            clearAll();
            return;
        } 
        var choice = document.getElementById("typeSelector").value;
        if (choice == 0) {
            signedMagnitude_re();
        }
        else if (choice == 1) {
            onesComplement_re();
        }
        else if (choice == 2) {
            twosComplement_re();
        }
        else if (choice == 3) {
            excessEightbits_re();
        }
        else {
            twosComplement_re();
        }
    }
	
    function signedMagnitude(){
		var out;
		var num = Number(document.getElementById("in1").value);
		var numAbs = Math.abs(num);
	 	var numAbsB = binary(numAbs, 7);
		if (num<0){
			out = "1"+numAbsB;
		}
		else{
			out = "0"+numAbsB;
		}
		if (num>127||num<-127){
			out = "Out of Range(-127~127)!"
		}
		document.getElementById("in2").value = out;
	}
	function signedMagnitude_re(){
		var out;
		var num = document.getElementById("in2").value;
		if (num[0] == 0){
			out = parseInt(num, 2);
		}
		else{
			var numS = num.slice(1);
			out = "-"+ parseInt(numS, 2);
		}
		if (num.length>8){
			out = "Out of Range(8bit binary)"
		}
		document.getElementById("in1").value = out;
	}
	function onesComplement(){
		var out;
		var num = Number(document.getElementById("in1").value);
		if (num<0){
			var pnum = 256-1-Math.abs(num);
		}
		else{
			var pnum = num;
		}
		out = binary(pnum, 8);
		if (num>127||num<-127){
			out = "Out of Range(-127~127)!"
		}
		document.getElementById("in2").value = out;
	}
	function onesComplement_re(){
		var out;
		var num = document.getElementById("in2").value;
		if (num[0] == 0){
			out = parseInt(num, 2);
		}
		else{
			out = parseInt(num, 2)+1-256;
		}
		if (num.length>8){
			out = "Out of Range(8bit binary)"
		}
		document.getElementById("in1").value = out;
	}
	function twosComplement(){
		var out;
		var num = Number(document.getElementById("in1").value);
		if (num<0){
			var pnum = 256-Math.abs(num);
		}
		else{
			var pnum = num;
		}
		out = binary(pnum, 8);
		if (num>127||num<-128){
			out = "Out of Range(-128~127)!"
		}
		document.getElementById("in2").value = out;
	}
	function twosComplement_re(){
		var out;
		var num = document.getElementById("in2").value;
		if (num[0] == 0){
			out = parseInt(num, 2);
		}
		else{
			out = parseInt(num, 2)-256;
		}
		if (num.length>8){
			out = "Out of Range(8bit binary)"
		}
		document.getElementById("in1").value = out;
	}
	function excessEightbits(){
		var out;
		var num = Number(document.getElementById("in1").value);
		var pnum = num+128;
		out = binary(pnum, 8);
		if (num>127||num<-128){
			out = "Out of Range(-128~127)!"
		}
		document.getElementById("in2").value = out;
	}
	function excessEightbits_re(){
		var out;
		var num = document.getElementById("in2").value;
		out = parseInt(num, 2)-128;
		if (num.length>8){
			out = "Out of Range(8bit binary)"
		}
		document.getElementById("in1").value = out;
	}
	function binary(inNum,inLen){
		var outList = new Array();
		var bNum;
		bNum = inNum.toString(2);
		if (inLen < bNum.length){return null;}
		if (inLen == bNum.length){return bNum;}
		var i = 0;
		while(i<inLen){
			if (i < (inLen-bNum.length)){
				outList.push(0);
			}
			else{
				outList.push(bNum[i-(inLen-bNum.length)])
			}
			i=i+1;
		}
		return outList.join("");
	}
    function clearAll() {
        document.getElementById("in1").value = '';
        document.getElementById("in2").value = '';
    }


    return(
		document.title = "Signed Binary Number Representation Convertor - UltraFish Plus",
		window.scrollTo(0, 0),
        <Container fluid id="educations" style={{backgroundColor: "#ffffff", padding: "20px"}}>
			<Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
			<Col xs={12} md={6} >
        		<a style={{ color:'#777', textDecoration: 'none' }} href="/" > Home </a> <font style={{color:"#777"}}>{'>'} Signed Binary Number Convertor</font>
			</Col>
			</Row>
            <h3 style={{width: "100%", textAlign: "center", marginBottom: "50px", marginTop: "20px", color: "#000000"}}><b>有符号二进制数表示方法转换器</b>
                <br></br><b>Signed Binary Number Convertor</b></h3>
            <Row style={{justifyContent: "center", alignContent: "center", height: "80%"}}>
            <Col xs={11} sm={6} md={4}>
                <Form.Label>Type:</Form.Label>
                <Form.Group controlId="typeSelector" onChange={clearAll}>
                <Form.Select aria-label="typeSelector" >
                        <option value ="0">Signed Magnitude (-127~127)</option>
						<option value ="1">One’s Complement (反码) (-127~127)</option>
						<option value ="2" selected = "selected">Two’s Complement (补码) (-128~127)</option>
						<option value ="3">Excess (Biased)128 (-128~127)</option>
                </Form.Select>
                </Form.Group>
                <br></br>
                </Col>
                
            </Row>
            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={11} sm={6} md={4} >

                <Form>
                    <Form.Group className="test" controlId='in1'>
                        <Form.Label>Decimal:</Form.Label>
                        <Form.Control  placeholder="Enter Dec" onInput={dtob}/>
                    </Form.Group>
                </Form>

                </Col>
                
            </Row>
            <Row style={{height: "4"}}>
                <br></br>
            </Row>
            <Row style={{justifyContent: "center", alignContent: "center", height: "80%"}}>
                <Col xs={11} sm={6} md={4}>
                <Form>
                    <Form.Group className="test" controlId='in2'>
                        <Form.Label>Binary:</Form.Label>
                        <Form.Control  placeholder="Enter Bin" onInput={btod}/>
                    </Form.Group>
                </Form>
                </Col>
            </Row>
            
            <Row className="justify-content-center">
            <Col xs={11} sm={6} md={4}>
            <Alert variant="light" style={{width: "100%",textAlign: "center",}}>
                <b>Please select type and enter decimal or binary number, range is limited</b>
                </Alert>
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
            <Button variant='outline-secondary' href='https://ultrafish.cn/2020/09/19/Signed-binary-number-representation/' style={{width:"250px",textAlign: "center",}}><b>查看原文 Back to the Post</b></Button>{""}
            </Row>
			<br></br>
            
            <Row className="justify-content-center">
            <Link to="/" style={{width:"275px",textAlign: "center",}} >
            <Button style={{width:"100%",textAlign: "center",}} variant='outline-secondary' ><b>回到主页 Back to the Main Page</b></Button>{""}
            </Link>
            </Row>

			<br></br>
			 <Row className="justify-content-center">
            <Button variant='outline-secondary' href='https://ultrafish.io/about' style={{width:"250px",textAlign: "center",}}><b>报告问题 Report Issues</b></Button>{""}
			</Row>
            <Row style={{height: "4"}}>
                
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                
            </Row>
        </Container>
    );
}

export default Signed_Binary_Number_Convertor;