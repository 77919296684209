import React, { useState } from 'react'
import {Form, Col, Button, Nav, Table, Accordion, Alert} from 'react-bootstrap'
import Row from "react-bootstrap/row"
import Container from "react-bootstrap/container"
import ReactMarkdown from 'react-markdown'
// import MathJax from 'better-react-mathjax'
import {Link} from "react-router-dom";

var size=7;
var totalGradePoint = 0;
var totalCredit = 0;
var flag = true;

const GPA_Calculator = () => {
  if (flag) {
    window.scrollTo(0, 0);
    flag = false;
  }
  const [fields, setFields] = useState([{
    id: 1,
    credit: "",
    grade: ""
  },
  {
    id: 2,
    credit: "",
    grade: ""
  },
  {
    id: 3,
    credit: "",
    grade: ""
  },
  {
    id: 4,
    credit: "",
    grade: ""
  },
  {
    id: 5,
    credit: "",
    grade: ""
  },
  {
    id: 6,
    credit: "",
    grade: ""
  },
  {
    id: 7,
    credit: "",
    grade: ""
  }])

  const handleChangeInput = (i, e) => {
    console.log(e.target.value);
    const values = [...fields]
    values[i][e.target.name] = e.target.value
    setFields(values)
    totalCredit = 0;
    totalGradePoint = 0;
    for (const j in values) {
        if (values[j].credit !== "" && values[j].grade<="4.3" && values[j].grade>="0.0" || values[j].grade==="S") {
            totalCredit += parseInt(values[j].credit);
            if (values[j].grade==="S") {
                totalGradePoint += 0;
            }
            else {
              totalGradePoint += (parseFloat(values[j].grade) * parseFloat(values[j].credit));
            }
        }
    }
  }


  const handleAdd = () => {
    setFields([...fields, { id: size + 1, credit: '', grade: '' }])
    size = size +1;
  }

  function calculate() {
    var result = totalGradePoint/totalCredit;
    result = result.toFixed(2);
    document.getElementById("sgpa").value = result;
    document.getElementById("tcredit").value = totalCredit;

    var previousGPA = document.getElementById('pgpa').value;
    var previousCredit = document.getElementById('pcredit').value;
    if (previousCredit !== "" && previousGPA !== "") {
      var PtotalGradePoint = totalGradePoint + (parseFloat(previousGPA) * parseFloat(previousCredit));
      var PtotalCredit = totalCredit + parseInt(previousCredit);

      var Presult = PtotalGradePoint/PtotalCredit;
      Presult = Presult.toFixed(2);
      document.getElementById("cgpa").value = Presult;
      document.getElementById("tcredit").value = PtotalCredit;
    }
    else {
      document.getElementById("cgpa").value = "";
    }
  }

  function clear() {
    setFields([{
      id: 1,
      credit: "",
      grade: ""
    },
    {
      id: 2,
      credit: "",
      grade: ""
    },
    {
      id: 3,
      credit: "",
      grade: ""
    },
    {
      id: 4,
      credit: "",
      grade: ""
    },
    {
      id: 5,
      credit: "",
      grade: ""
    },
    {
      id: 6,
      credit: "",
      grade: ""
    },
    {
      id: 7,
      credit: "",
      grade: ""
    }])
    size = 7;
    
    document.getElementById('pgpa').value = "";
    document.getElementById('pcredit').value = "";
    document.getElementById("sgpa").value = "";
    document.getElementById('cgpa').value = "";
    document.getElementById("tcredit").value = "";

    size=7;
    totalGradePoint = 0;
    totalCredit = 0;
  }

  return (
    document.title = "GPA Calculator - UltraFish Plus",
    
    <Container fluid style={{backgroundColor: "#ffffff", padding: "20px"}}>
        <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
        <Col xs={12} md={6} >
        <a style={{ color:'#777', textDecoration: 'none' }} href="/" > Home </a> <font style={{color:"#777"}}>{'>'} GPA Calculator</font>
			  </Col>
            <h3 style={{width: "100%", textAlign: "center", marginTop: "20px", color: "#000000"}}><b>GPA Calculator</b></h3>
            <p style={{width: "100%", textAlign: "center", marginBottom: "20px",color: "#000000"}}><b>Calculate GPA based on grade points and credits<br></br>(Apply to The Hong Kong Polytechnic University)</b></p>
          <Form >
            <Form.Group className="justify-content-center" style={{alignContent: "center"}}>
            <Row className="justify-content-center" style={{alignContent: "center"}}>
                <Col xs={3} sm={3} md={1} className="align-middle" >
                    <Form.Text type="text" className="align-middle" style={{fontSize: "18px", lineHeight:"38px"}}>No.</Form.Text>
                </Col>
                <Col xs={4} sm={4} md={2} className="align-middle" >
                    <Form.Text type="text" className="align-middle" style={{fontSize: "18px", lineHeight:"38px"}}>Credit</Form.Text>
                </Col>
                <Col xs={5} sm={5} md={2} className="align-middle" >
                    <Form.Text type="text" className="align-middle" style={{fontSize: "18px", lineHeight:"38px"}}>Grade</Form.Text>
                </Col>
            </Row>
              {fields.map((field, i) => (
                <div key={field.id}>
                  <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                    <Col xs={2} sm={3} md={1} >
                    
                        <Form.Text type="text" class="text-right" style={{fontSize: "15px", lineHeight:"35px"}}>Course#{field.id}</Form.Text>
                    </Col>
                    <Col xs={5} sm={4} md={2} >
                      
                      <Form.Control
                        controlId={i}
                        type="text"
                        placeholder="Enter credit"
                        name="credit"
                        value={field.credit}
                        onChange={e => handleChangeInput(i, e)}
                      />
                    </Col>
                    <Col xs={5} sm={5} md={2} >
                      
     
                      <Form.Select aria-label={i}
                      name="grade"
                      placeholder="Enter Grade"
                      value={field.grade}
                      onChange={e => handleChangeInput(i, e)}
                      >
                            <option value ="0" selected = "true">- select -</option>
                            <option value ="4.3">A+</option>
                            <option value ="4.0">A</option>
                            <option value="3.7">A-</option>
                            <option value="3.3">B+</option>
                            <option value="3.0">B</option>
                            <option value="2.7">B-</option>
                            <option value="2.3">C+</option>
                            <option value="2.0">C</option>
                            <option value="1.7">C-</option>
                            <option value="1.3">D+</option>
                            <option value="1.0">D</option>
                            <option value="0.0">F</option>
                            <option value="I">I</option>
                            <option value="L">L</option>
                            <option value="M">M</option>
                            <option value="N">N</option>
                            <option value="P">P</option>
                            <option value="S">S</option>
                            <option value="U">U</option>
                            <option value="W">W</option>
                            <option value="Z">Z</option>
                            <option value="LA">LA (Pending)</option>
                            <option value="LA">LA (Completed)</option>
                            <option value="T">T</option>
                          </Form.Select>
                    </Col>
                  </Row>
                  <Row className="mt-1"></Row>

                </div>

              ))}
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={5} >
                    <Button variant='outline-secondary' style={{width:"100%",textAlign: "center",}} onClick={() => handleAdd()}>
                    ➕ Add course
                    </Button>
                    </Col>
                </Row>
                <Row className="mt-2"></Row>
              <Row className="justify-content-center" style={{alignContent: "center"}}>
                <Col xs={12} sm={12} md={5} className="align-middle" >
                    <Form.Text type="text" className="align-middle" style={{fontSize: "18px", lineHeight:"38px"}}>Previous cumulative GPA and credits (optional):</Form.Text>
                </Col>
                </Row>
                <Row className="justify-content-center" style={{alignContent: "center"}}>
                <Col xs={2} sm={3} md={1} >
                    
                    <Form.Text type="text" className="text-right" style={{fontSize: "15px", lineHeight:"35px"}}>Previous: </Form.Text>
                </Col>
                <Col xs={5} sm={4} md={2} className="align-middle" >
                <Form>
                    <Form.Group controlId='pgpa'>
                        <Form.Control  placeholder="cGPA(optional)"/>
                    </Form.Group>
                </Form>
                </Col>
                <Col xs={5} sm={5} md={2} className="align-middle" >
                <Form>
                    <Form.Group controlId='pcredit'>
                        <Form.Control  placeholder="credits(optional)"/>
                    </Form.Group>
                </Form>
                </Col>
            </Row>

            </Form.Group>

            <Row><br></br></Row>
            
            <Row className="justify-content-center">
				        <Col xs={6} sm={6} md={2} >
                  <Button variant="success" style={{width:"100%",textAlign: "center",}} onClick={calculate}>
                  🟰 Calculate
                  </Button>{""}
                </Col>
                {/* <Col xs={6} sm={6} md={1} >
                  <Button variant="outline-secondary" style={{width:"100%",textAlign: "center",}} onClick={clear}>
                  Clear
                  </Button>{""}
                </Col> */}
            </Row>
            <Row><br></br></Row>
            <Row className="justify-content-center">
				        {/* <Col xs={6} sm={6} md={1} >
                  <Button variant="success" style={{width:"100%",textAlign: "center",}} onClick={calculate}>
                  Calculate
                  </Button>{""}
                </Col> */}
                <Col xs={6} sm={6} md={2} >
                  <Button variant="outline-secondary" style={{width:"100%",textAlign: "center",}} onClick={clear}>
                  ✖️ Clear
                  </Button>{""}
                </Col>
            </Row>
            <br></br>
            <Row className="justify-content-center">
				        <Col xs={6} sm={4} md={2} >
                <Form>
                    <Form.Group controlId='sgpa'>
                        <Form.Label>Semester GPA:</Form.Label>
                        <Form.Control  placeholder="sGPA" readOnly/>
                    </Form.Group>
                </Form>
                </Col>
            </Row>
            <Row className="mt-2"></Row>
            <Row className="justify-content-center">
				        <Col xs={6} sm={4} md={2} >
                <Form>
                    <Form.Group  controlId='cgpa'>
                        <Form.Label>Cumulative GPA:</Form.Label>
                        <Form.Control  placeholder="cGPA" readOnly/>
                    </Form.Group>
                </Form>
                </Col>
            </Row>
            <Row className="mt-2"></Row>
            <Row className="justify-content-center">
				        <Col xs={6} sm={4} md={2} >
                <Form>
                    <Form.Group  controlId='tcredit'>
                        <Form.Label>Total Credits:</Form.Label>
                        <Form.Control  placeholder="Total Credits" readOnly/>
                    </Form.Group>
                </Form>
                </Col>
            </Row>
            
          </Form>
        </Row>
        <br></br>
          <br></br>
          <Row className="justify-content-center">
          <Col xs={12} sm={12} md={5} >
          <Accordion style={{ width: '100%', alignItems: "center"}} defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header variant="secondary"><b><h5><strong>Instruction</strong></h5></b></Accordion.Header>
                    <Accordion.Body>
                      <ReactMarkdown>To calculate the GPA, please **enter the credit** and **select the grade** of your desired course.
                      </ReactMarkdown>
                      <ReactMarkdown>To add more courses, please click the **+ Add course** button.
                      </ReactMarkdown>
                      <ReactMarkdown>It is optional to enter the **previous cumulative GPA** and **credits**, for calculating the cumulative GPA below.
                      </ReactMarkdown>
                      <ReactMarkdown>To get the result, please click the **Calculate** button, and the **sGPA**, **cGPA**(if any) and **Total Credits** will be displayed.
                      </ReactMarkdown>
                      <ReactMarkdown>To clear all inputs, selections and results, please click the **Clear** button.
                      </ReactMarkdown>
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header variant="secondary"><b><h5><strong>Explanation</strong></h5></b></Accordion.Header>
                    <Accordion.Body>
                    <ReactMarkdown>The **Grade Point Average (GPA)** is the result of the accumulated value of the subject grade point multiplied by the subject credit value divided by the total credit value for subjects.
                    </ReactMarkdown>
                    <ReactMarkdown>The **GPA(Cumulative GPA/ cGPA)** is the cumulative average calculated for all subjects taken.
                    </ReactMarkdown>
                    <ReactMarkdown>The **Semester GPA (sGPA)** is the average calculated for all subjects taken for a particular semester.
                    </ReactMarkdown>
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header variant="secondary"><b><h5><strong>Calculation</strong></h5></b></Accordion.Header>
                    <Accordion.Body>
                    <ReactMarkdown>The **Grade Point Average (GPA)** is **the sum of the subject grade point multiplied by the subject credit value** divided by **the sum of credit value for subjects**.
                      </ReactMarkdown>
                    {/*<MathJax.Provider>*/}
                    {/*  <div>*/}
                    {/*    <MathJax.Node formula={`\\text{GPA} =\\frac{\\sum{ (\\text{Grade Point}\\times \\text{Credit})}}{ \\sum{ \\text{Credit}}}`} />*/}
                    {/*  </div>*/}
                    {/*</MathJax.Provider>*/}
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header variant="secondary"><b><h5><strong>Grade Table</strong></h5></b></Accordion.Header>
                    <Accordion.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Grade</th>
                          <th>Point</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>A+</td>
                          <td>4.3</td>
                        </tr>
                        <tr>
                          <td>A</td>
                          <td>4.0</td>
                        </tr>
                        <tr>
                          <td>A-</td>
                          <td>3.7</td>
                        </tr>
                        <tr>
                          <td>B+</td>
                          <td>3.3</td>
                        </tr>
                        <tr>
                          <td>B</td>
                          <td>3.0</td>
                        </tr>
                        <tr>
                          <td>B-</td>
                          <td>2.7</td>
                        </tr>
                        <tr>
                          <td>C+</td>
                          <td>2.3</td>
                        </tr>
                        <tr>
                          <td>C</td>
                          <td>2.0</td>
                        </tr>
                        <tr>
                          <td>C-</td>
                          <td>1.7</td>
                        </tr>
                        <tr>
                          <td>D+</td>
                          <td>1.3</td>
                        </tr>
                        <tr>
                          <td>D</td>
                          <td>1.0</td>
                        </tr>
                        <tr>
                          <td>F</td>
                          <td>0.0</td>
                        </tr>
                      </tbody>
                    </Table>
                   </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header variant="secondary"><b><h5><strong>Code/Mark Code</strong></h5></b></Accordion.Header>
                    <Accordion.Body>
                    <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Interpretation</th>
                        <th>Effect on GPA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>I</td>
                        <td>Assessment to be completed </td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>L</td>
                        <td>Subject to be continued in the following semester</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>M</td>
                        <td>Pass with Merit</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>N</td>
                        <td>Assessment is not required</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>P</td>
                        <td>Pass on an ungraded subject</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>S</td>
                        <td>Absent from all assessment components</td>
                        <td>Taken as zero </td>
                      </tr>
                      <tr>
                        <td>U</td>
                        <td>Fail on an ungraded subject</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>W</td>
                        <td>Withdrawn from subject</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>Z</td>
                        <td>Exempted</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>LA (Pending)</td>
                        <td>To be assessed later due to medical or other special circumstances</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>LA (Completed)</td>
                        <td>Results obtained after late assessment due to medical or other special circumstances</td>
                        <td>Omitted </td>
                      </tr>
                      <tr>
                        <td>T</td>
                        <td>Transfer of credit</td>
                        <td>Omitted </td>
                      </tr>
                    </tbody>
                  </Table>
                  <ReactMarkdown>Subject with the assigned codes I, L, M, N, P, U, W and Z will be **omitted** in the calculation of all GPAs. A subject with the assigned code S will be taken as **zero** in the calculation.
                    </ReactMarkdown>
                   </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <br></br>
          <ReactMarkdown>Source: [Explanation on Assessment Results - Academic Registry - The Hong Kong Polytechnic University](https://www.polyu.edu.hk/ar/polyu-students/explanation-on-assessment-results/)
          </ReactMarkdown>
      
          <ReactMarkdown>Disclaimer: This tool is for reference only. The actual results may vary.
          </ReactMarkdown>
          </Col>

          </Row>
          <Row className="justify-content-center">
            <Link to="/" style={{width:"275px",textAlign: "center",}} >
            <Button style={{width:"100%",textAlign: "center",}} variant='outline-secondary' ><b>回到主页 Back to the Main Page</b></Button>{""}
            </Link>
            </Row>
          <br></br>
          <Row className="justify-content-center">
                <Button variant='outline-secondary' href='https://ultrafish.io/about' style={{width:"250px",textAlign: "center",}}><b>报告问题 Report Issues</b></Button>{""}
          </Row>
        

      </Container>
    
  )
}

export default GPA_Calculator;