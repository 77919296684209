import React from "react";
// import beian from '../img/beian.png';
import "./style.css"

function Footer() {
  return (
      
    <div className="footer">
      <footer class="py-4 bg-light ">
        <div class="container" align="center">
          
          <p className="footer-font" class="m-1 text-center text-black">
          <font className="footer-font">Associated with </font>
            
            <a className="footer-font" style={{ color:'#000', textDecoration: 'none' }} href="https://ultrafish.io" > UltraFish.io </a>
            <br></br>
            <font className="footer-font">Copyright &copy; 2021-{new Date().getFullYear()} <a style={{ color:'#000', textDecoration: 'none' }} href="https://mike.ultrafish.io" >Mike_Zhang</a>. <br></br> All rights reserved.</font>
            <br></br>
            {/*<a className="footer-font" style={{ color:'#000', textDecoration: 'none' }} href="http://beian.miit.gov.cn" > <font className="footer-font">浙ICP备2020036956号</font> </a>*/}
            {/*<img src={beian} alt=""></img>*/}
            {/*<a className="footer-font" style={{ color:'#000', textDecoration: 'none' }} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33042102000661"> <font className="footer-font">浙公网安备 33042102000661号</font> </a>*/}
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;