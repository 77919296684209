import React from 'react';
import { Col, Container, Row, Form, Button,InputGroup, FormControl,Alert,Nav} from 'react-bootstrap';
import {Link} from "react-router-dom";
import "./style.css";



function Base_convertor(){
	
    
    function Convert(){
		if (document.getElementById("inFrom").value=="") {
			document.getElementById("inTo").value = "";
		}
		else {
			var num = document.getElementById("inFrom").value;
		var fromBase = document.getElementById("fromSelector").value;
		
		var flag = true;
		var str = num+'';
		
		for (var i=0; i<str.length;i++) {
			if ((str.charAt(i)).charCodeAt()<48||(parseInt(str.charAt(i))>= parseInt(fromBase))||isNaN(parseInt(str.charAt(i),fromBase))) {flag = false; break;}
		}
		
		if (flag == false) {
			document.getElementById("inTo").value = "NaN, invalid bits!";
		}
		else {
		var toBase = document.getElementById("toSelector").value;
		var toResult = parseInt(num,fromBase).toString(toBase);
		
		document.getElementById("inTo").value = toResult;
		}
		}
		
	}

	function mySwap() {
		clearAll();
		var inFrom = document.getElementById("fromSelector").value;
		document.getElementById("fromSelector").value=document.getElementById("toSelector").value;
		document.getElementById("toSelector").value=inFrom;

	}

	function dtob() {
		clearAll();
		document.getElementById("fromSelector").value = 10;
		document.getElementById("toSelector").value = 2;
	}

	function dtoh() {
		clearAll();
		document.getElementById("fromSelector").value = 10;
		document.getElementById("toSelector").value = 16;
	}

	function btoh() {
		clearAll();
		document.getElementById("fromSelector").value = 2;
		document.getElementById("toSelector").value = 16;
	}

	function clearAll(){
		document.getElementById("inFrom").value = "";
		document.getElementById("inTo").value = "";
	}

    return(
		document.title = "Multiple Bases Unsigned Integer Convertor - UltraFish Plus",
		window.scrollTo(0, 0),
        <Container fluid id="educations" style={{backgroundColor: "#ffffff", padding: "20px"}}>
			<Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>

			<Col xs={12} md={6} >
        		<a style={{ color:'#777', textDecoration: 'none' }} href="/" > Home </a> <font style={{color:"#777"}}>{'>'} Multiple Bases Unsigned Integer Convertor</font>
			</Col>
			</Row>
            <h3 style={{width: "100%", textAlign: "center", marginBottom: "50px", marginTop: "20px", color: "#000000"}}><b>多进制整数转换器</b>
                <br></br><b>Multiple Bases Unsigned Integer Convertor</b></h3>

            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={8} md={4} >
                <Form>
                    <Form.Group className="test" controlId='inFrom'>
                        <Form.Label>From:</Form.Label>
                        <Form.Control  placeholder="Enter" onInput={Convert}/>
                    </Form.Group>
                </Form>

                </Col>
                <Col xs={4} sm={2} md={1}>
                <Form.Label>Base:</Form.Label>
                <Form.Group controlId="fromSelector">
                <Form.Select aria-label="inSelect" >
                <option value ="2">2</option>
						  <option value ="3">3</option>
						  <option value="4">4</option>
						  <option value="5">5</option>
						  <option value="6">6</option>
						  <option value="7">7</option>
						  <option value="8">8</option>
						  <option value="9">9</option>
						  <option value="10" selected = "selected">10</option>
						  <option value="11">11</option>
						  <option value="12">12</option>
						  <option value="13">13</option>
						  <option value="14">14</option>
						  <option value="15">15</option>
						  <option value="16">16</option>
						  <option value="17">17</option>
						  <option value="18">18</option>
						  <option value="19">19</option>
						  <option value="20">20</option>
						  <option value="21">21</option>
						  <option value="22">22</option>
						  <option value="23">23</option>
						  <option value="24">24</option>
						  <option value="25">25</option>
						  <option value="26">26</option>
						  <option value="27">27</option>
						  <option value="28">28</option>
						  <option value="29">29</option>
						  <option value="30">30</option>
						  <option value="31">31</option>
						  <option value="32">32</option>
						  <option value="33">33</option>
						  <option value="34">34</option>
						  <option value="35">35</option>
						  <option value="36">36</option>
                </Form.Select>
                </Form.Group>
                </Col>

                

            </Row>
            <Row style={{height: "4"}}>
                <br></br>
            </Row>
            <Row style={{justifyContent: "center", alignContent: "center", height: "80%"}}>
                <Col xs={8} md={4}>
                <Form>
                    <Form.Group className="test" controlId='inTo'>
                        <Form.Label>To:</Form.Label>
                        <Form.Control  placeholder="Result" readOnly/>
                    </Form.Group>
                </Form>
                </Col>

                <Col xs={4} sm={2} md={1}>
                <Form.Label>Base:</Form.Label>
                <Form.Group controlId="toSelector">
                <Form.Select aria-label="inSelect" >
                            <option value ="2" selected = "selected">2</option>
						  <option value ="3">3</option>
						  <option value="4">4</option>
						  <option value="5">5</option>
						  <option value="6">6</option>
						  <option value="7">7</option>
						  <option value="8">8</option>
						  <option value="9">9</option>
						  <option value="10">10</option>
						  <option value="11">11</option>
						  <option value="12">12</option>
						  <option value="13">13</option>
						  <option value="14">14</option>
						  <option value="15">15</option>
						  <option value="16">16</option>
						  <option value="17">17</option>
						  <option value="18">18</option>
						  <option value="19">19</option>
						  <option value="20">20</option>
						  <option value="21">21</option>
						  <option value="22">22</option>
						  <option value="23">23</option>
						  <option value="24">24</option>
						  <option value="25">25</option>
						  <option value="26">26</option>
						  <option value="27">27</option>
						  <option value="28">28</option>
						  <option value="29">29</option>
						  <option value="30">30</option>
						  <option value="31">31</option>
						  <option value="32">32</option>
						  <option value="33">33</option>
						  <option value="34">34</option>
						  <option value="35">35</option>
						  <option value="36">36</option>
                </Form.Select>
                </Form.Group>
                
                </Col>
            </Row>
			<br></br>
			<Row className="justify-content-center">
				<Col xs={3} sm={2} md={1} >
				<Button style={{width: "100%",textAlign: "center",}} variant='outline-secondary' onClick={dtob}><b>10to2</b></Button>{""}
				</Col>
				<Col xs={3} sm={2} md={1} >
				<Button style={{width: "100%",textAlign: "center",}} variant='outline-secondary' onClick={dtoh}><b>10to16</b></Button>{""}
				</Col>
				<Col xs={3} sm={2} md={1} >
				<Button style={{width: "100%",textAlign: "center",}} variant='outline-secondary' onClick={btoh}><b>2to16</b></Button>{""}
				</Col>
				<Col xs={3} sm={2} md={1} >
				<Button style={{width: "100%",textAlign: "center",}} variant='outline-secondary' onClick={mySwap}><b>Swap</b></Button>{""}
				</Col>

            </Row>

            <Row className="justify-content-center">
			<Col xs={10} sm={8} md={6} >
            <Alert variant="light" style={{width: "100%",textAlign: "center",}}>
                <b>Please enter bits and select bases to go, base range: [2, 36]</b>
                </Alert>
				</Col>
            </Row>
			<br></br>
			<br></br>
			<br></br>
            <Row className="justify-content-center">
            <Link to="/" style={{width:"275px",textAlign: "center",}} >
            <Button style={{width:"100%",textAlign: "center",}} variant='outline-secondary' ><b>回到主页 Back to the Main Page</b></Button>{""}
            </Link>
            </Row>
			<br></br>
			 <Row className="justify-content-center">
            <Button variant='outline-secondary' href='https://ultrafish.cn/about' style={{width:"250px",textAlign: "center",}}><b>报告问题 Report Issues</b></Button>{""}
			</Row>
            <Row style={{height: "4"}}>
                
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                
            </Row>
        </Container>
    );
}

export default Base_convertor;