import React from 'react';
import { Alert, Col, Container, Row, Form, Card, Accordion,Image, Button} from 'react-bootstrap';
import { NavLink, Link} from "react-router-dom";
import "./style.css";
import logoUltraFish from '../img/ultrafish.png';
import logoUltraFishPlus from '../img/logoUltraFishPlus.png';
import bx from '../img/bx.png';
import d_b from '../img/d_b.png';
import jp from '../img/jp.png';
import db from '../img/db.png';
import ap from '../img/ap.png';
import gameoflife from '../img/gameoflife.png';
import morse from '../img/morse.png';
import handsyn from '../img/hand-syn.png';

function Home(){
    
    return(
        <Container fluid style={{backgroundColor: "#ffffff", padding: "20px"}}>
                <br></br>
            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={10} md={6} >
                    {/*<Alert key='danger' variant='danger'>*/}
                    {/*    Important Notice: <br></br>*/}
                    {/*    The domain name of UltraFish Plus has been updated from <i>plus.ultrafish.cn</i> to <b>plus.ultrafish.io</b> for better user experience. <br></br>*/}
                    {/*    Further updates will ONLY be made on <b>plus.ultrafish.io</b>. <br></br>*/}
                    {/*    The service of <i>plus.ultrafish.cn</i> will be terminated after <b>1,Oct,2023 23:59</b>. <br></br>*/}
                    {/*    Please visit <b><a href="https://plus.ultrafish.io">plus.ultrafish.io</a></b> for the latest content. <br></br>*/}
                    {/*    Thank you for your support! <br></br>*/}
                    {/*    Mike_Zhang, 2023-05-08 <br></br>*/}
                    {/*    <br></br>*/}
                    {/*    重要通知: <br></br>*/}
                    {/*    UltraFish Plus的域名已经从<i>plus.ultrafish.cn</i>更新为<b>plus.ultrafish.io</b>以提供更好的用户体验。 <br></br>*/}
                    {/*    后续的更新将仅在<b>plus.ultrafish.io</b>进行。 <br></br>*/}
                    {/*    <i>plus.ultrafish.cn</i>的服务将在<b>2023年10月1日 23:59</b>后终止。 <br></br>*/}
                    {/*    请访问<b><a href="https://plus.ultrafish.io">plus.ultrafish.io</a></b>以获取最新内容。 <br></br>*/}
                    {/*    感谢您的支持！ <br></br>*/}
                    {/*    Mike_Zhang, 2023-05-08 <br></br>*/}
                    {/*</Alert>*/}
                <Accordion style={{ width: '100%', alignItems: "center"}}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header variant="secondary"><b>Hello, UltraFish Plus!</b></Accordion.Header>
                    <Accordion.Body>
                    <center>
                            <Image variant="top" src={logoUltraFishPlus} style={{marginTop: "20px", width: "20%"}}/>
                            <br></br>
                            <br></br>
                            <p><strong>UltraFish Plus是基于我的博客UltraFish的一个开发性页面, 分享一些可交互的项目与工具。</strong></p>
                            <p><strong>UltraFish Plus is a developmental page based on the UltraFish, aiming to share some interactive projects and tools.</strong></p>
                    </center>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                </Col>
            </Row>
            <br></br>
            <br></br>


            {/* <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
               <Col xs={10} md={6} >
               <Card border="secondary" style={{ width: '100%'}}>
               <center><Card.Img variant="top" src={gameoflife} style={{marginTop: "20px", width: "15%"}}/></center>
              
                   <Card.Body>
                       <Card.Title>Conway's Game of Life
                  </Card.Title>
                       <Card.Text>
                       康威生命游戏
                       </Card.Text>
                       <Button variant="outline-secondary" href='https://plus.ultrafish.cn/gameoflife/'>进入 GO</Button>
                   </Card.Body>
               </Card>
               </Col>
            </Row> */}

            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
               <Col xs={10} md={6} >
               <Card border="secondary" style={{ width: '100%'}}>
               <center><Card.Img variant="top" src={logoUltraFishPlus} style={{marginTop: "20px", width: "15%"}}/></center>
              
                   <Card.Body>
                       <Card.Title>VisionFish
                  </Card.Title>
                       <Card.Text>
                       渔你观
                       </Card.Text>
                       <Button variant="outline-secondary" href='https://plus.ultrafish.io/vision/'>Vision 观</Button>
                   </Card.Body>
               </Card>
               </Col>
            </Row>

            <br></br>

            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={10} md={3}>
                    <Card border="secondary" style={{ height: '100%'}}>
                        <center><Card.Img variant="top" src={logoUltraFishPlus} style={{marginTop: "40px", width: "10%", marginBottom:"15px"}}/></center>

                        <Card.Body>
                            <Card.Title>DrawFish
                            </Card.Title>
                            <Card.Text>
                                渔你画
                            </Card.Text>
                            <Button variant="outline-secondary" href='https://plus.ultrafish.io/draw/'>Draw 画</Button>

                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={10} md={3} >
                    <Card border="secondary" style={{ height: '100%'}}>
                        <center><Card.Img variant="top" src={logoUltraFishPlus} style={{marginTop: "40px", width: "10%", marginBottom:"15px"}}/></center>

                        <Card.Body>
                            <Card.Title>ChatFish
                            </Card.Title>
                            <Card.Text>
                                渔你聊
                            </Card.Text>
                            <Button variant="outline-secondary" href='https://plus.ultrafish.io/chat/'>Chat 聊</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <br></br>

            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={10} md={3}>
                    <Card border="secondary" style={{ height: '100%'}}>
                        <center><Card.Img variant="top" src={handsyn} style={{marginTop: "40px", width: "70%", marginBottom:"15px"}}/></center>

                        <Card.Body>
                            <Card.Title>Handwriting Synthesis with RNNs
                            </Card.Title>
                            <Card.Text>
                                基于RNN的手写笔迹合成
                            </Card.Text>
                            <Button variant="outline-secondary" href='https://plus.ultrafish.io/handwriting-synthesis/'>进入 GO</Button>

                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={10} md={3} >
                    <Card border="secondary" style={{ height: '100%'}}>
                        <center><Card.Img variant="top" src={morse} style={{marginTop: "50px", width: "70%", marginBottom:"30px"}}/></center>

                        <Card.Body>
                            <Card.Title>Morse Code Translator
                            </Card.Title>
                            <Card.Text>
                                莫尔斯码转换器
                            </Card.Text>
                            <Button variant="outline-secondary" href='https://plus.ultrafish.io/morse-code-translator/'>进入 GO</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <br></br>

            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={10} md={3}>
                    <Card border="secondary" style={{ height: '100%'}}>
                        <center><Card.Img variant="top" src={gameoflife} style={{marginTop: "26px", width: "15%"}}/></center>

                        <Card.Body>
                            <Card.Title>Conway's Game of Life
                            </Card.Title>
                            <Card.Text>
                                康威生命游戏
                            </Card.Text>
                            <Button variant="outline-secondary" href='https://plus.ultrafish.io/gameoflife/'>进入 GO</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={10} md={3} >
                    <Card border="secondary" style={{ height: '100%'}}>
                        <center><Card.Img variant="top" src={ap} style={{marginTop: "25px", width: "30%", marginBottom:"25px"}}/></center>

                        <Card.Body>
                            <Card.Title>GPA Calculator
                            </Card.Title>
                            <Card.Text>
                                Apply to The Hong Kong Polytechnic University
                            </Card.Text>
                            <Link to="/GPA-Calculator">
                                <Button variant="outline-secondary">进入 GO</Button>
                            </Link>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <br></br>
            
            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={10} md={3}>
                <Card border="secondary" style={{ height: '100%'}}>
                <center><Card.Img variant="top" src={bx} style={{marginTop: "35px", width: "50%",marginBottom:"30px"}}/></center>
                
                    <Card.Body>
                        <Card.Title>Multiple Bases Unsigned Integer Convertor</Card.Title>
                        <Card.Text>
                            多进制整数转换器
                        </Card.Text>
                        <Link to="/Base-convertor"><Button variant="outline-secondary">进入 GO</Button></Link>

                    </Card.Body>
                </Card>
                </Col>
                <Col xs={10} md={3} >
                <Card border="secondary" style={{ height: '100%'}}>
                <center><Card.Img variant="top" src={d_b} style={{marginTop: "20px", width: "30%"}}/></center>
                <br></br>
                    <Card.Body>
                        <Card.Title>Floating Point Numbers Convertor</Card.Title>
                        <Card.Text>
                            浮点数表示方法转换器
                        </Card.Text>
                        <Link to="/IEEE-754-Single-Representation">
                        <Button variant="outline-secondary">进入 GO</Button>
                        </Link>
                    </Card.Body>
                </Card>
                </Col>
            </Row>

            <br></br>
            
            <Row className="justify-content-center" style={{alignContent: "center", height: "80%"}}>
                <Col xs={10} md={3} >
                <Card border="secondary" style={{ height: '100%'}}>
                <center><Card.Img variant="top" src={jp} style={{marginTop: "20px", width: "30%"}}/></center>
                
                    <Card.Body>
                        <Card.Title>Josephus Problem with Python</Card.Title>
                        <Card.Text>
                            Python对约瑟夫问题的高效解决方法
                        </Card.Text>
                        <Link to="/Josephus-Problem">
                        <Button variant="outline-secondary">进入 GO</Button>
                        </Link>
                        
                    </Card.Body>
                </Card>
                </Col>
                <Col xs={10} md={3} >
                <Card border="secondary" style={{ height: '100%'}}>
                <center><Card.Img variant="top" src={db} style={{marginBottom: "30px", marginTop: "30px", width: "30%"}}/></center>
                
                    <Card.Body>
                        <Card.Title>Signed Binary Number Convertor</Card.Title>
                        <Card.Text>
                            有符号二进制数表示方法转换器
                        </Card.Text>
                        <Link to="/Signed-Binary-Number-Convertor">
                        <Button variant="outline-secondary">进入 GO</Button>
                        </Link>
                        
                    </Card.Body>
                </Card>
                </Col>

            </Row>

            <br></br>
            <br></br>
        </Container>
    );
}

export default Home;