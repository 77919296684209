import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './components/ScrollToTop.js';
//import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import {
  Header,
  Footer,
  Base_convertor,
  IEEE_754_Single_Representation,
  Josephus_Problem,
  Signed_Binary_Number_Convertor,
  GPA_Calculator,
  Home,
  Page,
} from "./components";

ReactDOM.render(
  <BrowserRouter>
    <div>
    <ScrollToTop>
    <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Base-convertor" element={<Base_convertor />} />
        <Route path="/IEEE-754-Single-Representation" element={<IEEE_754_Single_Representation/>} />
        <Route path="/Josephus-Problem" element={<Josephus_Problem/>} />
        <Route path="/Signed-Binary-Number-Convertor" element={<Signed_Binary_Number_Convertor/>} />
        <Route path="/GPA-Calculator" element={<GPA_Calculator/>} />
      </Routes>
    </ScrollToTop>
    <Footer/>
  </div>
</BrowserRouter>,
  // <Router>
  //   <Header />
  //     <Routes>
  //       <Route path="/" element={<Home />} />
  //       <Route path="/Base-convertor" element={<Base_convertor />} />
  //       <Route path="/IEEE-754-Single-Representation" element={<IEEE_754_Single_Representation/>} />
  //       <Route path="/Josephus-Problem" element={<Josephus_Problem/>} />
  //       <Route path="/Signed-Binary-Number-Convertor" element={<Signed_Binary_Number_Convertor/>} />
  //     </Routes>
  //   <Footer />
  // </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
